const partnersArr = [
    "ada",
    "anex",
    "Avilon",
    "mail",
    "major",
    "mersedes",
    "messe",
    "mts",
    "pkka",
    "plehanov",
    "podvorie",
    "skolkovo",
    "swimcup",
    "usadba",
    "yota"

]

export {partnersArr};